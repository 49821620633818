export function sendGtag(type = "event", ...args) {
  if (window.location.hostname !== "businessdraft.com") return;

  if (!window.gtag) {
    console.error("Google Analytics: gtag is not defined");
    return;
  }

  try {
    window.gtag(type, ...args);
  } catch (e) {
    console.error(e);
  }
}

export const gtagSetUser = (user_id) => sendGtag("set", {user_id});
export const gtagNewUser = (user_id) => sendGtag("event", "new_user", {user_id});
export const gtagNewEnterprise = (enterprise_id) => sendGtag("event", "new_enterprise", {enterprise_id});
export const gtagNewCompany = (company_id) => sendGtag("event", "new_company", {company_id});
export const gtagOpportunityViewed = (uuid) => sendGtag("event", "opportunity_viewed", {uuid});
export const gtagApplyClicked = (uuid) => sendGtag("event", "apply_clicked", {uuid});
export const gtagApplicationSubmitted = (uuid) => sendGtag("event", "application_submitting", {uuid});
